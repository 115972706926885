import { Box, Button, Paper, Stack } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react'

import CheckBoxControl from './FormControls/CheckBoxControl';

import ButtonControl from './FormControls/ButtonControl';

import { GetAllCategories } from '../Handlers/Handlers.js';

function IndicatorsSelectionPage(props) {
    const { requirements, selectedRequirement } = props;
    const categories = GetAllCategories();
    const [indicators, setIndicators] = useState([])
    const [selectedIndicators, setSelectedIndicators] = useState(props.indicators);
    const [selectedRequirementname, setSelectedRequirementName] = useState();
    const [minMaxValues, setMinMaxValues] = useState(props.minMaxValues)
    const handleCheckBoxChange = (e) => {
        const { value, checked } = e.target;
        let tempArray = selectedIndicators;
        if (checked) {
            tempArray = [...tempArray, parseInt(value)]
        } else {
            tempArray = tempArray.filter((v) => {
                return v !== parseInt(value)
            })
        }
        setSelectedIndicators(tempArray);
        props.setData(tempArray)
    }

    //getting requirements by id
    useEffect(() => {


        fetch("https://da-platform-backend-sfoip.ondigitalocean.app/api/v1/requirements/" + selectedRequirement + "")
            .then((response) => response.json())
            .then((apiData) => {
                setSelectedRequirementName(apiData.data.name)
                setIndicators(apiData.data.indicators);
            });
    }, []);

    const minMaxChange = (values) => {

        setMinMaxValues(values)

    }

    return (
        <Fragment>
            <Stack direction="row" spacing={2} justifyContent="center">{
                //categories list as header
                categories.map((category) => {
                    return (
                        < Button
                            key={category.id}
                            style={{ backgroundColor: '#1c5a73' }}
                            sx={
                                {
                                    width: '100%',
                                    boxShadow: 0,
                                    borderRadius: 0,
                                    //backgroundColor: '#1c5a73',
                                    color: 'white'
                                }
                            } >
                            {category.name}
                        </Button>
                    )
                })
            }
            </Stack>

            < Box sx={{
                display: 'flex',
                marginTop: 1,
                height: 300,
                border: 0,
            }}>
                <Paper sx={{
                    height: 300,
                    border: 0,
                    marginLeft: 0.1,
                    width: 190,
                    backgroundColor: 'white',
                    '&:hover': {
                        backgroundColor: 'white',
                        opacity: [0.9, 0.8, 0.7],
                    },
                    textAlign: 'center'

                }} elevation={3} > {
                        selectedRequirementname
                    }

                </Paper>
                <Paper sx={{
                    overflowY: "auto",
                    maxHeight: "550px",
                    width: '100%',
                    marginLeft: 1,
                    border: 0,
                    backgroundColor: 'white',
                    '&:hover': {
                        backgroundColor: 'white',
                        opacity: [0.9, 0.8, 0.7],
                    },
                    textAlign: 'left',
                    paddingLeft: 5,
                    whiteSpace: 'nowrap'

                }} elevation={3} >
                    {<CheckBoxControl
                        items={indicators}
                        isIndicator={true}
                        values={selectedIndicators}
                        minMaxValues={minMaxValues}
                        minMaxChange={minMaxChange}
                        onChange={handleCheckBoxChange}
                    />}
                </Paper>
            </Box>
            < Stack style={{ marginTop: 10 }} justifyContent="flex-end" direction="row" spacing={2}>

                <ButtonControl onClick={props.onClickOkHandler} label={"Ok"} />
            </Stack>
        </Fragment >
    )
}

export default IndicatorsSelectionPage