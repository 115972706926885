import { Box, Button, Paper, Stack, Divider } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react'
import CheckBoxControl from './FormControls/CheckBoxControl';
import ButtonControl from './FormControls/ButtonControl';


function EditRequirements(props) {
    const { requirements, onClickPrev, onClickNext, setCategories, updateMinMaxValues } = props;
    const [minMaxValues, setMinMaxValues] = useState(props.minMaxValues)
    const [selectedRequirements, setSelectedRequirements] = useState(requirements);
    const [currentCategoryRequirements, setCurrentCategoryRequirments] = useState([])



    const handleCheckBoxChange = (e) => {
        const { value, checked } = e.target;
        let tempArray = selectedRequirements;
        if (checked) {
            tempArray = [...tempArray, parseInt(value)]
        } else {
            tempArray = tempArray.filter((v) => { return v !== parseInt(value) })
        }
        setSelectedRequirements(tempArray);

    }


    const minMaxChange = (values) => {
        setMinMaxValues(values)
    }
    return (
        <Fragment>
            <Paper sx={{
                // height: 300,
                height: '300px',
                overflowY: 'auto',
                flexDirection: "column",
                width: '90%',

                marginLeft: 5,
                border: 0,
                borderRadius: '5px 5px 0 0',
                backgroundColor: 'white',
                /*'&:hover': {
                    backgroundColor: 'white',
                    opacity: [0.9, 0.8, 0.7],
                },*/
                textAlign: 'left',
                paddingLeft: 5,
                whiteSpace: 'nowrap',





            }} elevation={3} >
                {<CheckBoxControl
                    items={currentCategoryRequirements}
                    values={selectedRequirements}
                    isIndicator={false}
                    minMaxValues={minMaxValues}
                    minMaxChange={minMaxChange}
                    onChange={handleCheckBoxChange}


                />}

            </Paper>

        </Fragment>


    )
}
export default EditRequirements;
