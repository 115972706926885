import { FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Paper, Radio, RadioGroup, Stack } from '@mui/material';
import React, { Fragment, useState } from 'react'
import Card from '../UIComponents/Card';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import ButtonControl from '../UIComponents/FormControls/ButtonControl';
import CardMini from '../UIComponents/CardMini';

import BackButtonControl from '../UIComponents/FormControls/BackButtonControl';


function IndicatorsDB(props) {
    const [objectives, setObjectives] = useState(props.objectives);
    const [indicators, setIndicators] = useState(props.indicators);
    const [checked, setChecked] = React.useState([0]);

    const [selectedValue, setSelectedValue] = React.useState('a');
    const [value, setValue] = React.useState('female');
    const handleChange = (event) => {
        setValue(event.target.value);
    };
    const indicatorsDBInfo = {
        header: "Which data source is the most reliable?        ",
        description: `According to our quality criteria, the best database for retrieving the indicator is selected automatically in each case. If you have any specific preferences, you can change this selection manually. `,
        bodyClassName: "cardBody"
    };



    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    let deletedIndicators = []


    const onChangeIndicatorHandler = (e) => {
        const { value, checked } = e.target;
        Object.entries(indicators).map(([keyName, objectives]) => {

            //console.log(objectives.id)
        }
        )
        if (!checked) {
            deletedIndicators.push(value)
        }
        else {
            deletedIndicators = deletedIndicators.filter((c) => { return c !== value })
        }
    }

    const updateindicators = () => {
        Object.entries(indicators).map(([keyName, objectives]) => {
        }
        )
    }

    const onClickNextHandler = () => {
        updateindicators();

        props.onClickNext(1);
        props.setShowIndicatorDB1();
    }
    const onClickPrevHandler = () => {
        //   props.onClickPrev(1);
        props.setShowIndicatorDB1();
    }


    return (
        <Card cardInfo={indicatorsDBInfo} >

            <Grid container spacing={10}>

                <Grid item xs={6}   > <b> Objectives and indicators </b> </Grid>
                <Grid item xs={6} style={{ paddingLeft: "50px", borderBottom: "20px" }}> <b> Databases </b></Grid>
            </Grid>
            <Grid container spacing={10}>
                <Grid item xs={6} align="center" ></Grid>
                <Grid item xs={6} style={{ paddingLeft: "38px", borderBottom: "20px" }}>WB&nbsp;&nbsp; UNO&nbsp;&nbsp;UN</Grid>
            </Grid>







            <FormGroup>
                {Object.entries(indicators).map(([keyName, objectives]) => {
                    return (
                        <div key={keyName}>
                            <FormLabel component="legend">{keyName}</FormLabel>
                            {Object.entries(objectives).map(([objKey, objValue]) => {
                                return (
                                    <div key={objKey}>
                                        <Grid container rowSpacing={10} columnSpacing={{ xs: 12, sm: 8, md: 12 }}>
                                            <Grid item xs >
                                                <Grid container spacing={10}  >
                                                    <Grid item xs={5}>

                                                        <FormControlLabel
                                                            control={< Checkbox defaultChecked />}
                                                            label={objValue.title}
                                                            value={objValue.id}
                                                            onChange={onChangeIndicatorHandler}

                                                        />
                                                    </Grid>
                                                    <Grid item style={{ marginLeft: "3rem" }} >
                                                        <FormControl>
                                                            <RadioGroup
                                                                row
                                                                defaultValue="top"
                                                            >
                                                                <FormControlLabel
                                                                    value="top"
                                                                    control={<Radio />}
                                                                />
                                                                <FormControlLabel
                                                                    value="start"
                                                                    control={<Radio />}
                                                                />
                                                                <FormControlLabel
                                                                    value="bottom"
                                                                    control={<Radio />}
                                                                />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                )
                            })}
                        </div>
                    )
                })}

            </FormGroup>

            <Grid item xs={12} >
                <Stack justifyContent="flex-end" direction="row" spacing={2}>
                    <BackButtonControl label={"Back"} onClick={onClickPrevHandler} variant="outlined" />
                    <ButtonControl label={"Next"} onClick={onClickNextHandler} />
                </Stack>
            </Grid>

        </Card >
    )
}

export default IndicatorsDB