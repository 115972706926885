import React from 'react'
import { Button } from '@mui/material'
import { FormLabel, Grid, Stack } from '@mui/material';

const ButtonControl = (props) => {
    const { label, onClick, color } = props
    //const anger = createColor('#F40B27');
    return (
        <Stack  >
            <Button onClick={onClick} style={{ backgroundColor: "#1c5A73" }} variant="contained">{label}  </Button>
        </Stack>
    )
}
export default ButtonControl;