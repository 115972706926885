import { useState, Fragment, useEffect } from 'react';
import Framing from './Framing';
import Requirements from './Requirements';
import Indicators from './Indicators';
import IndicatorsDB from './IndicatorsDB';
import Weighting from './Weighting';
import IndicatorWeighting from './IndicatorWeighting';
import Options from './Options';
import RobustAnalysis from './RobustAnalysis';
import Evaluation from './Evaluation';
import CustomStepper from '../UIComponents/CustomStepper';
import { GetIndicatorData, getCategoryData, getRequirementData } from '../Handlers/Handlers';
import { ConstructionOutlined } from '@mui/icons-material';
import { useQuery } from '@tanstack/react-query';

import { Axios } from 'axios';
const Decision = (props) => {

    // This variable holds value of current step.
    const [stepNumber, setStepNumber] = useState(0);

    const [isRobustAnalysis, setIsRobustAnalysis] = useState(false);

    const [framingData, setFramingData] = useState({
        framingTitle: '',
        framingPlan: [],
        framingTeam: [],
        framingDate: new Date()
    });
    const [requirements, setRequirements] = useState([]);
    const [indicators, setIndicators] = useState([]);
    const [options, setOptions] = useState([]);
    const [showIndicatorsDB, setShowIndicatorDB] = useState(false);
    const [showIndicatorWeighting, setShowIndicatorWeighting] = useState(false);
    const [minMaxValues, setMinMaxValues] = useState({});//minmax values
    const [categories, setCategories] = useState([]);
    const [indicator, setIndicator] = useState();

    const [requirementWeighting, setRequirementWeighting] = useState([]);
    const [indicatorWeighting, setIndicatorWeighting] = useState({});
    const [convertedData, setConvertedData] = useState([]);
    const framingHandler = (framingData) => {
        setFramingData(framingData);
    }
    const requirementsHandler = (requirements) => {

        requirementWeightingHandler();
        percentageHandler(requirementWeighting);
        setRequirements(requirements);
    }
    const indicatorHandler = (indicators) => {

        setIndicators(indicators);
    }

    const percentageHandler = (tempArray) => {

        let totalWeight = 0;
        Object.entries(tempArray).map(([keyName, objective]) => {
            const objectWeight = objective.weight ?? 1
            totalWeight = totalWeight + objectWeight

        })
        Object.entries(tempArray).map(([keyName, objective]) => {
            const objectWeight = objective.weight ?? 1
            objective.percentage = ((objectWeight / totalWeight) * 100).toFixed(1);
        })
        setRequirementWeighting(tempArray)

    }
    const optionsHandler = (options) => {

        indicatortWeightingHandler();
        setOptions(options)
    }

    const createRequirementObject = (data) => {
        let tempArray = requirementWeighting;
        let tempObject = {}
        let index = tempArray.findIndex(x => x.id == data.id);
        if (index === -1) {

            tempObject.id = data.id;
            tempObject.title = data.name;
            tempObject.categoryID = data.category;
            tempObject.weight = 1;
            tempObject.percentage = 25;
            tempArray.push(tempObject)
        }
        setRequirementWeighting(tempArray)
    }

    const createIndicatorObject = (data) => {
        let tempObject = indicatorWeighting
        let tempIndicator = {}
        let index = -1;
        if (tempObject[data.requirement.name]) {
            index = tempObject[data.requirement.name].findIndex(x => x.id == data.id);
        }
        if (index === -1) {
            tempIndicator.id = data.id;
            tempIndicator.objectiveID = data.requirement.id;
            tempIndicator.title = data.name;
            tempIndicator.weight = 1;
            tempObject[data.requirement.name] = tempObject[data.requirement.name] ?
                [...tempObject[data.requirement.name], tempIndicator] : [tempIndicator];
        }
        setIndicatorWeighting(tempObject);

    }
    const requirementWeightingHandler = () => {
        requirements.map(requirement => {
            getRequirementData(requirement, createRequirementObject)
        })

    }

    const indicatortWeightingHandler = () => {
        //console.log(indicators)
        indicators.map(indicator => {
            GetIndicatorData(indicator, createIndicatorObject)
        })


    }

    const updateMinMaxValues = (updateMinMaxValues) => {
        setMinMaxValues(updateMinMaxValues)
    }


    const nextStepNumberHandler = (step) => {
        setStepNumber(stepNumber + step);
    }
    const prevStepNumberHandler = (step) => {
        setStepNumber(stepNumber - step);
    }

    const setShowIndicatorDB1 = () => {
        setShowIndicatorDB(!showIndicatorsDB);

    }
    const setShowIndicatorWeighting1 = () => {
        setShowIndicatorWeighting(!showIndicatorWeighting);

    }

    const fetchData = () => {
        // Fetch data from an external source
        fetch("https://da-platform-backend-sfoip.ondigitalocean.app/api/v1/indicators")
            .then(response => response.json())
            .then(data => {
                const convertedData = {};
                data.data.forEach(item => {
                    const values = {};
                    Object.keys(item.values).forEach(key => {
                        values[key] = parseFloat(item.values[key]);
                    });
                    convertedData[item.id] = values;
                });
                setConvertedData(convertedData);
                //console.log(convertedData);
            })
            .catch(error => console.error(error));

        //(data => setConvertedData(data.data));

    }
    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        setStepNumber(stepNumber);
    }, [stepNumber])
    useEffect(() => {
        setRequirementWeighting(requirementWeighting);
    }, [requirementWeighting]);
    //console.log(convertedData)
    return (
        <Fragment >

            <CustomStepper onClick={setStepNumber} step={stepNumber} />
            {stepNumber === 0
                && <Framing
                    defaultValues={framingData}
                    setData={framingHandler}
                    onClickNext={nextStepNumberHandler} />
            }
            {stepNumber === 1
                && <Requirements
                    requirements={requirements}
                    minMaxValues={minMaxValues}
                    updateMinMaxValues={updateMinMaxValues}
                    setData={requirementsHandler}
                    setCategories={setCategories}
                    setIndicators={indicatorHandler}
                    onClickPrev={prevStepNumberHandler}
                    onClickNext={nextStepNumberHandler} />}

            {!showIndicatorsDB && stepNumber === 2 &&
                <Indicators
                    requirements={requirements}
                    minMaxValues={minMaxValues}
                    updateMinMaxValues={updateMinMaxValues}
                    indicators={indicators}
                    setData={indicatorHandler}
                    setShowIndicatorDB1={setShowIndicatorDB1}
                    onClickPrev={prevStepNumberHandler}
                    onClickNext={nextStepNumberHandler} />}

            {showIndicatorsDB &&
                <IndicatorsDB
                    requirements={requirements}
                    indicators={indicators}
                    setShowIndicatorDB1={setShowIndicatorDB1}
                    onClickPrev={prevStepNumberHandler}
                    onClickNext={nextStepNumberHandler} />}
            {stepNumber === 3 &&
                <Options
                    options={options}
                    setData={optionsHandler}
                    onClickPrev={prevStepNumberHandler}
                    onClickNext={nextStepNumberHandler} />}
            {!showIndicatorWeighting && stepNumber === 4 &&
                <Weighting
                    requirementWeighting={requirementWeighting}
                    setData={setRequirementWeighting}
                    setShowIndicatorWeighting1={setShowIndicatorWeighting1}
                    onClickPrev={prevStepNumberHandler}
                    onClickNext={nextStepNumberHandler} />}
            {showIndicatorWeighting &&
                <IndicatorWeighting
                    indicators={indicatorWeighting}
                    setData={setIndicatorWeighting}
                    setShowIndicatorWeighting1={setShowIndicatorWeighting1}
                    onClickPrev={prevStepNumberHandler}
                    onClickNext={nextStepNumberHandler} />}
            {stepNumber === 5 &&
                <Evaluation
                    indicators={indicatorWeighting}
                    objectives={requirementWeighting}
                    convertedData={convertedData}
                    options={options}
                    minMaxValues={minMaxValues}
                    onClickPrev={prevStepNumberHandler}
                    onClickNext={nextStepNumberHandler}
                    step={isRobustAnalysis}
                    setRobustAnalysis={setIsRobustAnalysis} />}

            {stepNumber === 6 &&
                <Evaluation
                    indicators={indicatorWeighting}
                    objectives={requirementWeighting}
                    minMaxValues={minMaxValues}
                    options={options}
                    convertedData={convertedData}
                    setData={requirementsHandler}
                    onClickPrev={prevStepNumberHandler}
                    onClickNext={nextStepNumberHandler}
                    step={isRobustAnalysis}
                    setRobustAnalysis={setIsRobustAnalysis} />}

        </Fragment>

    )
};

export default Decision;
