import React, { useState } from "react";

import { TextField } from "@mui/material";
import {
    FormControl,
    FormGroup,
} from "@mui/material";
const min = 1;
const max = 100;

function NumberPicker(props) {
    const { weight, onChange, keyID, objectiveId } = props;

    const [value, setValue] = useState(weight);
    return (
        <div className="numberPicker">
            <div className="numberPicker_header"></div>
            <div className="numberPicker_body">
                <FormControl>
                    <FormGroup>

                        <TextField
                            fullWidth
                            type="number"
                            inputProps={{ min, max }}
                            value={value}
                            onChange={(e) => {
                                var value = parseInt(e.target.value, 10);
                                if (value > max) value = max;
                                if (value < min) value = min;
                                setValue(value)
                                onChange(keyID, value, objectiveId)
                            }}
                            variant="outlined"
                        />
                    </FormGroup>
                </FormControl>
            </div>
        </div >
    );
}

export default NumberPicker;
