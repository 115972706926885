import React from 'react'

import './Card.css'

const Card = (props) => {
    const classes = 'card ' + props.className;

    const bodyClass = props.cardInfo.bodyClassName;
    const pClass = props.cardInfo.pClassName;
    return (
        <div className={classes} style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: '15rem',
            height: '36rem',
        }}>
            <h4 className="cardHeader" >{props.cardInfo.header}</h4>
            <p className={pClass}>{props.cardInfo.description}</p>
            <div className={bodyClass}>
                {props.children}
            </div>
        </div>


    )
}

export default Card;