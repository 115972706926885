export const MenuItems = [
    /* {
         title: 'Team',
         url: '/team',
         cName: 'nav-links',
     },
 
     {
         title: 'Guide',
         url: '/guide',
         cName: 'nav-links',
     },
 
     {
         title: 'Languages',
         url: '/languages',
         cName: 'nav-links',
     },
 
     {
         title: 'Login',
         url: '/login',
         cName: 'nav-links-mobile',
     },*/

]