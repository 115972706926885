import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Decision from "./Decision";
import './LandingPage.css'


const Video = styled("video")({
    width: "100%",
    height: "100%",
    objectFit: "cover",
});


const Image = styled("img")({
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "cover",
});



export default function LandingPage() {
    const [showDecision, setShowDecision] = useState(false);

    if (showDecision) {
        return <Decision />;
    }

    return (
        <>
            <Box>
                <Box sx={{ display: "flex", flexDirection: "row" }}>

                    <Box sx={{ width: "50%", pt: 15, pl: 30 }}>
                        <Typography variant="h4" gutterBottom sx={{ color: "#1c5a73", fontWeight: 700, whiteSpace: "pre-line", fontSize: 45, mb: '6%' }}>
                            Transform{"\n"}
                            data into{"\n"}

                            action
                        </Typography>
                        <Button
                            variant="contained"
                            onClick={() => setShowDecision(true)}
                            sx={{
                                borderRadius: "10px",  // Updated border radius value
                                height: "60px",
                                width: "180px",
                                padding: "1",
                                backgroundColor: "#1c5a73",
                                textTransform: "capitalize",
                                fontWeight: 700,
                                fontSize: 45,
                                mb: '6%',
                                "&:hover": {
                                    backgroundColor: "#ffffff",
                                    color: "#1c5a73"
                                }
                            }}
                        >
                            <span style={{ fontSize: "26px", fontWeight: 600 }}>Explore</span>

                        </Button>

                        <Typography variant="body1" gutterBottom sx={{ color: "#1c5a73", fontSize: 27, }}>
                            Join CLEANTECH HUB today and start <br /> making data-informed decisions to scale <br /> your greentech business!
                        </Typography>
                    </Box>
                    <Box sx={{ width: "50%", height: "50%", marginTop: '10%' }}>
                        <Video autoPlay muted loop>
                            <source src="../Images/Logo/CleantechPath.mp4" type="video/mp4" />
                        </Video>
                    </Box>
                </Box>



                {/*<div className="rectangle-section">
                    <div className="text-container">
                        <svg width="90vw" height="100vh">
                            <polygon
                                //points="0,0 150,0 150,150   70,200  0,150 "
                                points="0,0 500,0 500,500   300,300  0,500 "
                                stroke="black"
                                strokeWidth="2"
                            />

                            <text
                                x="50%"
                                y="50%"
                                dominantBaseline="middle"
                                textAnchor="middle"
                                fontSize="24px"
                                fill="#ffffff"
                                fontWeight="bold"
                            >
                                Heading
                            </text>
                            <text
                                x="50%"
                                y="60%"
                                dominantBaseline="middle"
                                textAnchor="middle"
                                fontSize="16px"
                                fill="#ffffff"
                            >
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer condimentum tristique ante, eget tempus risus rutrum et.
                            </text>
                        </svg>
                    </div>
                    <div className="image-container">
                        <img src="../Images/Logo/Cleantech_colour.png" alt="Image" />
                    </div>
                        </div>*/}








                <Box sx={{ display: "flex", flexDirection: "row", pt: '5%' }}>
                    <Box sx={{ width: "50%", p: 2, backgroundColor: '#E8EDE5', pt: 10 }}>
                        <Typography variant="h2" sx={{ color: "#446E5A", fontSize: 45, fontWeight: 700, pl: '30%' }} gutterBottom >
                            Our Offer
                        </Typography>
                        <Typography variant="body1" sx={{ color: "#446E5A", fontSize: 27, fontWeight: 600, pl: '30%' }} gutterBottom>
                            Decision-making aid for greentech <br /> owners using decision-analysis
                        </Typography>


                        <Typography variant="body1" gutterBottom sx={{ color: "#446E5A", fontSize: 20, pl: '30%' }}>
                            The Cleantech Hub aims to help cleantech owners select the most appropriate developing or emerging region to scale their technology. Therefore, Scaletech's decision analytics platform compares various costs, benefits and risks of different regions based on data from reliable databases.
                        </Typography>
                    </Box>
                    <Box sx={{ width: "50%", p: 2, backgroundColor: '#446E5A' }}>
                        <Image src="../Images/Logo/cleanech_fan.png" alt="image" />
                    </Box>

                </Box>

                <Box sx={{


                    m: '5%'

                }}
                >
                    <Typography variant="h1" sx={{ color: "#1c5a73", fontWeight: 600, fontSize: 45 }} gutterBottom>
                        How do we do it
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Box sx={{ width: "50%", p: 2, position: "relative", display: "flex", justifyContent: "center", alignItems: "center", height: 500 }}>
                            <Image src="../Images/Logo/keynote1.png" alt="image" />
                            <Box
                                sx={{
                                    position: "absolute",
                                    marginTop: '-25%',
                                    width: "70%",
                                    height: "50%",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: "transparent",
                                    padding: "10px",
                                    textAlign: "center",
                                    color: "#1c5a73",


                                }}
                            >

                                <Typography variant="h3" sx={{ fontSize: 35, fontWeight: 600 }}>
                                    Identify your market requirements
                                </Typography>
                                <Typography variant="body1" gutterBottom sx={{ fontSize: 20, pt: '4%' }}>
                                    By guiding our users through a decision-analytical process, we enable proactive and value-focused scaling decisions.
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{ width: "50%", p: 2, position: "relative", display: "flex", justifyContent: "center", alignItems: "center", height: 500 }}>
                            <Image src="../Images/Logo/keynote2.png" alt="image" />
                            <Box
                                sx={{
                                    position: "absolute",
                                    marginTop: '-25%',
                                    width: "70%",
                                    height: "50%",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: "transparent",
                                    padding: "10px",
                                    textAlign: "center",
                                    color: "#1c5a73",


                                }}
                            >

                                <Typography variant="h3" sx={{ fontSize: 35, fontWeight: 600 }}>                                    Underpin by reliable indicators
                                </Typography>
                                <Typography variant="body1" gutterBottom sx={{ fontSize: 20, pt: '4%' }}>
                                    By providing access to current data from valid data sources, we enable cleantech owners to make data-informed decisions.
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{ width: "50%", p: 2, position: "relative", display: "flex", justifyContent: "center", alignItems: "center", height: 500 }}>
                            <Image src="../Images/Logo/keynote3.png" alt="image" />
                            <Box
                                sx={{
                                    position: "absolute",
                                    marginTop: '-25%',
                                    width: "70%",
                                    height: "50%",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: "transparent",
                                    padding: "10px",
                                    textAlign: "center",
                                    color: "#1c5a73",


                                }}
                            >

                                <Typography variant="h3" sx={{ fontSize: 35, fontWeight: 600 }}>
                                    Ranking of the most suitable markets
                                </Typography>
                                <Typography variant="body1" gutterBottom sx={{ fontSize: 20, pt: '4%' }}>
                                    By comparing developing and newly industrializing regions worldwide, we contribute to a more sustainable future by driving growth and innovation in the cleantech industry. </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>



            </Box>
        </>
    );
}
