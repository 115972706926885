import React, { useEffect, useState } from 'react';
import { GetIndicators } from '../Handlers/Handlers';


import Card from '../UIComponents/Card';

import RequirementsPage from '../UIComponents/RequirementsPage';

import RequirementsSelectionPage from '../UIComponents/RequirementsSelectionPage';
import EditRequirements from '../UIComponents/EditRequirements';

const Requirements = (props) => {

    const { requirements, setData, setIndicators, setCategories, updateMinMaxValues } = props;
    //console.log(props.minMaxValues)
    // requirement header information
    const requirementsInfo = {
        header: "What is important to your decision?​",
        description: 'Consider what requirements your new target market must meet in order for your product to scale successfully. Select the most important requirements from the following lists. Start with the sector-specific requirements and then screen the general requirements lists (environmental, economic & finance, social & demographic, political, technological, legal & regulatory). Please specify whether each requirement is to minimize or maximize this requirement. For example, you may want to maximize the potential for funding while minimizing the cost. ​',
    };
    const requirementsView = {
        header: "What is important to your decision?​",
        description: `These are all selected requirements. Please go back if you want to modify the selection ​`,
    };
    const [isRequirementSelection, setIsRequirmentSelection] = useState(true);

    const [selectedIndicators, setSelectedIndicators] = useState([]);
    const onClickPrevHandler = (requirements) => {
        setData(requirements);
        setIndicators(selectedIndicators);
        props.onClickPrev(1);
    }
    const onClickShowRequirementsPage = (requirements) => {
        setData(requirements);
        setIsRequirmentSelection(false);
    }
    const onClickNextHandler = (requirements) => {
        setData(requirements);
        setIndicators(selectedIndicators);
        props.onClickNext(1);
    }
    useEffect(() => {
        let indicators = selectedIndicators;
        requirements.map(requirement => {
            fetch("https://da-platform-backend-sfoip.ondigitalocean.app/api/v1/requirements/" + requirement + "")
                .then((response) => response.json())
                .then((apiData) => {
                    apiData.data.indicators.map((indicator) => {
                        if (indicators.indexOf(indicator.id) === -1) {
                            indicators.push(indicator.id);
                        }

                    });
                });

        })
        setSelectedIndicators(indicators);
    }, [requirements]);
    return (
        <Card cardInfo={isRequirementSelection ? requirementsInfo : requirementsView}>

            <div className='objectiveForm'>
                {isRequirementSelection &&
                    //requirement selection page
                    < RequirementsSelectionPage
                        requirements={requirements}
                        setCategories={setCategories}
                        minMaxValues={props.minMaxValues}
                        updateMinMaxValues={updateMinMaxValues}
                        onClickPrev={onClickPrevHandler}
                        onClickNext={onClickShowRequirementsPage}
                    />
                }
                {!isRequirementSelection &&
                    < RequirementsPage
                        isRequirement={true}
                        requirements={requirements}
                        setCategories={setCategories}
                        minMaxValues={props.minMaxValues}
                        updateMinMaxValues={updateMinMaxValues}
                        onClickPrev={onClickPrevHandler}
                        onClickNext={onClickNextHandler}
                    />


                }
            </div>
        </Card >
    )
};

export default Requirements;