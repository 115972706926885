import { FormControl, FormControlLabel, FormGroup, Checkbox, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MinMaxSwitch from '../MinMaxSwitch';

const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 180,
    },
});

function CheckBoxControl(props) {
    const { name, onChange, items, values, minMaxChange, minMaxValues } = props;


    const [tempMinMax, setTempMinMax] = useState(minMaxValues);
    const ischecked = (itemID) => {
        let flag = false
        {
            values && values.forEach(value => {
                if (value == itemID) {
                    flag = true

                }
            })
        }
        return flag
    }

    const updateMinMax = (value) => {
        let tempObject = tempMinMax;
        let key = value[0];
        tempObject[key] = value[1];
        setTempMinMax(tempObject)
        minMaxChange(tempMinMax)
    }
    //console.log(items);
    return (
        <FormControl>
            <FormGroup >

                {items.map((item, index) => (

                    <Grid key={item.id} container>
                        <Grid key={item.id} item xs={10} center={0 ? 0 : 1}>
                            <FormControlLabel

                                key={item.id}
                                control={<Checkbox sx={{
                                    // color: 'red',
                                    '&.Mui-checked': {
                                        color: '#57819A',
                                    },
                                }} />}
                                label={item.name}
                                value={item.id}
                                onChange={onChange}
                                checked={ischecked(item.id)}
                            />
                        </Grid>
                        <Grid item xs={2} center={0 ? 0 : 1}>
                            {!props.isIndicator ? (
                                <>
                                    max <MinMaxSwitch id={item.id} minMaxValues={tempMinMax} updateMinMax={updateMinMax} /> min
                                    <CustomWidthTooltip title={item.shortInfo} arrow>
                                        <InfoOutlinedIcon
                                            color="action"
                                            style={{
                                                paddingTop: '10px',
                                                paddingLeft: '18px',
                                            }}
                                        />
                                    </CustomWidthTooltip>
                                </>
                            ) : (
                                <>
                                    <CustomWidthTooltip title={item.description} arrow>

                                        <InfoOutlinedIcon
                                            color="action"
                                            style={{
                                                paddingTop: '18px',
                                                paddingLeft: '18px',
                                            }}
                                        />
                                    </CustomWidthTooltip>
                                </>
                            )}
                        </Grid>
                    </Grid>
                ))}
            </FormGroup>
        </FormControl>
    )
}

export default CheckBoxControl;