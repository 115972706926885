import React, { Component } from "react"
import { Link, useMatch, useResolvedPath } from "react-router-dom"
import { MenuItems } from "./MenuItems"
import "./Navbar.css";
import { Button } from "../UIComponents/Button"

class Navbar extends Component {
  state = { clicked: false }

  handleClick = () => {
    this.setState({ clicked: !this.state.clicked })
  }

  render() {
    return (
      <nav className="NavbarItems">
        <div className="navbar-logo">
          <Link to="/">
            <img src="../Images/Logo/Cleantech_WHITE.png" alt="ScaleTech" style={{ width: '100%', height: '100%' }} />
          </Link>
        </div>
        <div className="menu-icon" onClick={this.handleClick}>
          <i className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}></i>
        </div>
        <ul className={this.state.clicked ? "nav-menu active" : "nav-menu"}>
          {MenuItems.map((item, index) => {
            return (
              <li key={index}>
                <CustomLink className={item.cName} to={item.url}>
                  {item.title}
                </CustomLink>
              </li>
            )
          })}
        </ul>
        {/* <Button><CustomLink className="login-button" to="/login">Login</CustomLink></Button>*/}
      </nav>
    );
  }
};

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to)
  const isActive = useMatch({ path: resolvedPath.pathname, end: true })

  return (
    <div className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </div>
  )
}

export default Navbar;
