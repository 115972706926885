import Card from '../UIComponents/Card';
import React, { useEffect, useState } from 'react'
import { FormLabel, Grid, Slider, Stack } from '@mui/material';
import ButtonControl from '../UIComponents/FormControls/ButtonControl';
import { index } from '../DataLayer/Index'
import StackedBarChart from '../UIComponents/StackedBarChart'
import BackButtonControl from '../UIComponents/FormControls/BackButtonControl';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import IconButton from '@mui/material/IconButton';
import { Box } from '@mui/system';
import { ContentCutOutlined } from '@mui/icons-material';




function Evaluation(props) {


    const indicatorsInfo = {

        header: "Which region fits your requirements best? ​",
        description: `The following evaluation shows your pre-selected regions in order of their match to your requirements. 
        You can also see how your regions compare globally and if there are other regions that match your requirements by clicking on "most suitable regions worldwide".​​`,
    };

    const RobustInfo = {
        header: "How robust is the result?",
        description: `You can check the robustness of the results of your decision analysis by changing the weighting of the requirements you have chosen. The result is more robust the less the order of the regions changes with weighting changes​.`,
    };

    const marks = [
        {
            value: 1,
            label: '1',
        },
        {
            value: 100,
            label: '100',
        },
    ];
    const info = props.step ? RobustInfo : indicatorsInfo;
    //let [countiresList, indexData] = index();
    //console.log(indexData);
    //console.log(props.convertedData)
    const [objectives, setObjectives] = useState(props.objectives);
    const percentageHandler = () => {
        let indicators = props.indicators;

        Object.entries(indicators).map(([keyName, objectives]) => {
            let totalWeight = 0;
            Object.entries(objectives).map(([objKey, objValue]) => {

                const objectWeight = objValue.weight ?? 1
                totalWeight = totalWeight + objectWeight

            })
            Object.entries(objectives).map(([objKey, objValue]) => {
                const objectWeight = objValue.weight ?? 1
                objValue.percentage = ((objectWeight / totalWeight) * 100).toFixed(2);

            })
        })

        return indicators;

    }


    const indicators = percentageHandler();
    const options = props.options;

    const [countryList, setCountryList] = useState();
    const [seriesData, setSeriesData] = useState([]);

    const [showSelectedCountries, setShowSelectedCountries] = useState(true);
    const [buttonText, setButtonText] = useState();
    const [minmax, setMinmax] = useState(props.minMaxValues);



    // Method to get Stacked bar chart data of top 5 countries.
    const filterTop5Countries = () => {
        const [objectiveData, sortedIndex] = loadSeriesData();

        let top5Countires = [];
        let count = 0;

        for (const key in sortedIndex) {

            if (count < 5) {
                top5Countires.push(key);
                count++;
            }
            else {
                break;
            }
        }

        return getSeriesData(objectiveData, top5Countires);

    }

    // Method to get Stacked bar chart data of user selected countries.
    const filterSelectedCountries = () => {
        const [objectiveData, sortedIndex] = loadSeriesData();


        let selectedCountries = [];
        for (const key in sortedIndex) {
            options.map(option => {
                if (option.countryName === key) {
                    selectedCountries.push(key);
                }
            })

        }

        return getSeriesData(objectiveData, selectedCountries);

    }

    // returns data for series data based on the parent method (either top 5 countries or user selected countries).
    function getSeriesData(objectiveData, countryList) {


        setCountryList(countryList);

        let seriesData = [];
        objectiveData.map(series => {
            let tempObject = {};
            let tempIndexValue = [];
            tempObject.name = series.name;
            countryList.map(country => {
                tempIndexValue.push(parseFloat(series.data[country]).toFixed(2));

            });
            tempObject.data = tempIndexValue;
            seriesData.push(tempObject);

        });
        return seriesData;
    }

    // Load SeriesData for Stacked Bar Chart.
    const loadSeriesData = () => {
        let objectivePercentage = {};
        objectives.map(objective => {
            objectivePercentage[objective.id] = objective.percentage;

        });
        const indexSum = {};
        const objectiveData = [];
        //taking 
        for (const key in indicators) {

            let objective = key;
            let indicatorsList = indicators[key];
            const tempObject = {};
            // Sum all the index values of indicators of an objective
            indicatorsList.map(indicator => {
                // //console.log(props.convertedData)
                //console.log(indexData[indicator.id])
                //getting indicator data and comparing all the countries
                const filterData = props.convertedData[indicator.id];
                const unwantedNames = [
                    "Africa Eastern and Southern",
                    "Africa Western and Central",
                    "Arab World",
                    "Caribbean small states",
                    "Early-demographic dividend",
                    "East Asia & Pacific",
                    "East Asia & Pacific (excluding high income)",
                    "East Asia & Pacific (IDA & IBRD countries)",
                    "Europe & Central Asia",
                    "Europe & Central Asia (excluding high income)",
                    "Europe & Central Asia (IDA & IBRD countries)",
                    "Fragile and conflict affected situations",
                    "Heavily indebted poor countries (HIPC)",
                    "IBRD only",
                    "IDA & IBRD total",
                    "IDA blend",
                    "IDA only",
                    "IDA total",
                    "Late-demographic dividend",
                    "Latin America & Caribbean",
                    "Latin America & Caribbean (excluding high income)",
                    "Latin America & the Caribbean (IDA & IBRD countries)",
                    "Least developed countries: UN classification",
                    "Low & middle income",
                    "Low income",
                    "Lower middle income",
                    "Middle East & North Africa",
                    "Middle East & North Africa (excluding high income)",
                    "Middle East & North Africa (IDA & IBRD countries)",
                    "Middle income",
                    "Other small states",
                    "Pacific island small states",
                    "Pre-demographic dividend",
                    "Small states",
                    "South Asia",
                    "South Asia (IDA & IBRD)",
                    "Sub-Saharan Africa",
                    "Sub-Saharan Africa (excluding high income)",
                    "Sub-Saharan Africa (IDA & IBRD countries)",
                    "Upper middle income",
                    "Euro area",
                    "High income",
                    "North America",
                    "OECD members",
                    "Central Europe and the Baltics",
                    "European Union",
                    "Post-demographic dividend"
                ];

                const filteredData = Object.keys(filterData)
                    .filter((key) => !unwantedNames.includes(key))
                    .reduce((obj, key) => {
                        obj[key] = filterData[key];
                        return obj;
                    }, {});
                //console.log(filterData);
                //console.log(filteredData);
                const indicatorIndexData = filteredData;
                const min = Math.min(...Object.values(indicatorIndexData));
                const max = Math.max(...Object.values(indicatorIndexData));
                //console.log(min);
                //console.log(max);
                let scaleList = {};
                for (const country in indicatorIndexData) {

                    const scalingValue = (((indicatorIndexData[country]) - (Math.min(...Object.values(indicatorIndexData)))) * 100 / ((Math.max(...Object.values(indicatorIndexData))) - (Math.min(...Object.values(indicatorIndexData)))));
                    scaleList[country] = scalingValue;
                    //console.log(scalingValue)

                }
                //console.log(scaleList)


                for (const country in scaleList) {
                    //console.log(indicatorIndexData)
                    //console.log(country)
                    //const scalingValue = (indicatorIndexData[country] * 100 / (Math.max(...Object.values(indicatorIndexData))));
                    const scalingValue1 = (((indicatorIndexData[country]) - (Math.min(...Object.values(indicatorIndexData)))) * 100 / ((Math.max(...Object.values(indicatorIndexData))) - (Math.min(...Object.values(indicatorIndexData)))));

                    if (minmax[indicator.objectiveID] === 1) {

                        const minValue = (scaleList[country] * (-1) + (Math.max(...Object.values(scaleList))) + (Math.min(...Object.values(scaleList))));
                        //console.log(scaleList)
                        // //console.log(minValue)
                        tempObject[country] = ((indicator.percentage / 100) * (minValue)) + (tempObject[country] || 0);
                    } else {

                        tempObject[country] = ((indicator.percentage / 100) * (scalingValue1)) + (tempObject[country] || 0);
                    }
                }
            });


            for (const country in tempObject) {
                tempObject[country] = (tempObject[country] * ((objectivePercentage[indicators[key][0].objectiveID] / 100)));

            }
            objectiveData.push({
                name: objective,
                data: tempObject


            })

        }
        objectiveData.map(objective => {
            for (const country in objective.data) {
                indexSum[country] = objective.data[country] + (indexSum[country] || 0);


            }

        })

        return [objectiveData, sorted(indexSum)];
    }

    // To sorted an Object in desending order
    const sorted = (object) => {

        return (
            Object.keys(object)
                .sort((key1, key2) => object[key2] - object[key1])
                .reduce(
                    (obj, key) => ({
                        ...obj,
                        [key]: object[key]
                    }),
                    {}
                ))

    }

    const handleChange = Name => (event, newValue) => {
        const tempObject = objectives.map(object => {
            if (object.id === Name) {
                //console.log(object.id)
                return { ...object, percentage: newValue };
            }
            return object;
        });
        setObjectives(tempObject);
    }

    // Toggle Contries between Selected Countires list and top 5 countries.
    const toggleCountryList = () => {
        setShowSelectedCountries(!showSelectedCountries);
    }
    // OnclickNext will navigate to next page.
    const onClickNextHandler = () => {
        if (!props.step) {
            props.setRobustAnalysis(true);
            props.onClickNext(1);
        }
        else {
            props.setData(objectives)

        }
    }
    const onDownload = () => {
        const link = document.createElement("a");
        link.download = `download.txt`;
        link.href = "./download.txt";
        link.click();
    };
    // OnclickPrev will navigate to previous page.
    const onClickPrevHandler = () => {

        if (props.step) {
            props.setData(objectives);
            props.setRobustAnalysis(false);
            props.onClickPrev(1);
        }
        else {
            props.onClickPrev(1);
        }

    }


    useEffect(() => {
        if (showSelectedCountries) {
            setSeriesData(filterSelectedCountries());
            setButtonText('best fitting regions worldwide ​');
        }
        else {
            setSeriesData(filterTop5Countries())
            setButtonText('my pre-selected regions');
        }
    }, [showSelectedCountries, objectives])


    return (
        <Card cardInfo={info}>
            <Grid container rowSpacing={10} justify="flex-start">
                <Grid item xs={8}>
                    <StackedBarChart seriesData={seriesData} countryList={countryList} />
                </Grid>
                <Grid item xs={4}>
                    {props.step && (
                        <Grid item xs={12} center={0 ? 0 : 1}>
                            <Box style={{ maxHeight: '340px', overflowY: 'auto', overflowX: 'hidden' }}>
                                {objectives.map((objective) => (
                                    <div>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <FormLabel style={{ overflowWrap: 'break-word' }}>{objective.title}</FormLabel>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box sx={{ margin: '0 20px' }}>
                                                    <Slider
                                                        aria-label="Default"
                                                        value={objective.percentage}
                                                        valueLabelDisplay="Auto"
                                                        step={5}
                                                        min={0}
                                                        max={100}
                                                        marks={marks}
                                                        onChange={handleChange(objective.id)}
                                                        sx={{
                                                            '& .MuiSlider-thumb': {
                                                                color: "#57819a"
                                                            },
                                                            '& .MuiSlider-track': {
                                                                color: "#57819a"
                                                            },
                                                            '& .MuiSlider-rail': {
                                                                color: "#57819a"
                                                            },
                                                            '& .MuiSlider-active': {
                                                                color: "#57819a"
                                                            }
                                                        }}
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </div>
                                ))}
                            </Box>
                        </Grid>
                    )}
                    <Grid item xs={12} center={0 ? 0 : 1} style={{ marginTop: '5px' }}>
                        <BackButtonControl label={buttonText} onClick={toggleCountryList} />
                    </Grid>



                    <Grid item xs={12}>
                        {!props.step && <Stack justifyContent="flex-end" direction="row" spacing={2} style={{ marginTop: '85.4%' }}>
                            <BackButtonControl label={"Back"} onClick={onClickPrevHandler} variant="outlined" />
                            <ButtonControl label={"Next"} onClick={onClickNextHandler} />

                        </Stack>}
                        {props.step && <Stack justifyContent="flex-end" direction="row" spacing={2} style={{ marginTop: '1%' }}>
                            <BackButtonControl label={"Back"} onClick={onClickPrevHandler} variant="outlined" />


                            <IconButton color="primary" size="small" aria-label="Download document" component="label">
                                {/*<FileDownloadIcon />*/}
                            </IconButton>

                        </Stack>}
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
}
export default Evaluation;