import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useState } from "react";
import LandingPage from "../DecisionAnalysis/LandingPage";
import './CustomStepper.css';

const steps = [
    "Framing",
    "Requirements",
    "Indicators",
    "Regions​",
    "Weighting",
    "Evaluation",
    "Robustness Analysis",
];

export default function CustomStepper(props) {
    const [activeStep, setActiveStep] = useState(props.step);

    React.useEffect(() => {
        setActiveStep(props.step);
    }, [props.step]);

    const isMobileView = window.innerWidth <= 600; // define the width below which it is mobile view

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row', // change to row
                // justifyContent: 'flex-start',
                color: '#1c5A73',
                alignItems: 'stretch', // add alignItems to make sure Stepper takes full height
                margin: '2rem auto',
                width: '50%',
                maxWidth: '70%',
                marginLeft: '3rem',
                marginTop: '5rem',
                height: '6%', // add height to Box container
                '@media (min-width: 600px)': {
                    width: '90%',
                    maxWidth: '600px',
                },
                '@media (min-width: 960px)': {
                    maxWidth: '960px',
                },
                position: 'fixed',
                left: 0,
                '& .MuiStepLabel-root .Mui-completed': {
                    color: '#1C5A73', // circle color (COMPLETED)
                },
                '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                {
                    color: 'common.white', // Just text label (COMPLETED)
                },
                '& .MuiStepLabel-root .Mui-active': {
                    color: '#1C5A73', // circle color (ACTIVE)
                },
                '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                {
                    color: 'common.white', // Just text label (ACTIVE)
                },
                '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                    fill: 'common.white', // circle's number (ACTIVE)
                },

            }}
        >



            <Stepper activeStep={activeStep} orientation="vertical" sx={{ color: '#1c5A73 !important' }}>
                {steps.map((label, index) => {
                    return (
                        <Step key={label}>
                            <StepLabel
                                className={isMobileView ? "mobile-step-label" : ""}
                                title={label}  // Add the title attribute with the step label as its value
                                onClick={() => {
                                    if (index < props.step) {
                                        setActiveStep(index);
                                        props.onClick(index);
                                    }
                                }}
                            >
                                {label}
                            </StepLabel>

                        </Step>
                    );
                })}
            </Stepper>
        </Box>
    );
}
