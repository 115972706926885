import { Fragment, useEffect, useState } from 'react';
import Decision from '../DecisionAnalysis/Decision';
import LandingPage from '../DecisionAnalysis/LandingPage';
import './Body.css';

const Body = () => {

    return (
        <Fragment>
            <div className="background-image">
                <LandingPage />
            </div>
        </Fragment>
    )
}

export default Body;