import React, { useEffect, useState } from 'react';
import { GetIndicators } from '../Handlers/Handlers';


import Card from '../UIComponents/Card';
import IndicatorsSelectionPage from '../UIComponents/IndicatorsSelectionPage';
import RequirementsPage from '../UIComponents/RequirementsPage';
const Indicators = (props) => {

    //const { indicators, setData } = props;
    const { requirements, setData, indicators } = props;
    // requirement header information
    const indicatorsInfo = {
        header: "How can your requirements be measured?​",
        description: `Clicking on each requirement shows you which indicators have been selected by Scaletech's scaling experts to meet your needs. You can make adjustments by selecting or deselecting indicators.  ​`,
    };
    const [isIndicatorSelection, setIsIndicatorSelection] = useState(true);
    const [selectedRequirement, setSelectedRequirement] = useState();




    const onClickRequirementHandler = (requirementId) => {
        setIsIndicatorSelection(false)
        setSelectedRequirement(requirementId)

    }
    const onClickOkHandler = () => {
        setIsIndicatorSelection(true)
    }
    const onClickPrevHandler = (indicators) => {
        setData(indicators);
        props.onClickPrev(1);
    }

    const onClickNextHandler = (indicators) => {
        setData(indicators);
        props.onClickNext(1);
    }


    return (
        <Card cardInfo={indicatorsInfo} >
            <div className='indicatorForm'>
                {isIndicatorSelection &&
                    < RequirementsPage
                        isRequirement={false}// false in indicators
                        requirements={requirements}
                        indicators={indicators}
                        onClickRequirementHandler={onClickRequirementHandler}
                        onClickPrev={onClickPrevHandler}
                        onClickNext={onClickNextHandler}
                    />
                }

                {!isIndicatorSelection &&
                    < IndicatorsSelectionPage
                        requirements={requirements}
                        selectedRequirement={selectedRequirement}
                        minMaxValues={props.minMaxValues}
                        updateMinMaxValues={props.updateMinMaxValues}
                        indicators={indicators}
                        setData={setData}
                        onClickOkHandler={onClickOkHandler}
                    />
                }
            </div>
        </Card >
    )

}
export default Indicators;
