import React from "react";
import { Button } from '@mui/material'
import { FormLabel, Grid, Stack } from '@mui/material';

const BackButtonControl = (props) => {
    const { label, onClick, variant } = props
    return (
        <Stack sx={{ boxShadow: 22, borderRadius: 1, color: '#000000' }} >
            <Button
                onClick={onClick}
                sx={{
                    color: "#1c5A73",
                    borderColor: "#1c5A73",
                    '&:hover': {
                        borderColor: "#1c5A73",
                    },
                }}
                variant={variant}
            >
                {label}
            </Button>
        </Stack>
    )
}
export default BackButtonControl;
