import React from 'react';
import Body from './Layout/Body';
import Navbar from './Layout/Navbar';
import Team from "./Layout/pages/Team"
import Login from "./Layout/pages/Login"
import Guide from "./Layout/pages/Guide"
import Languages from "./Layout/pages/Languages"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query'
const queryClient = new QueryClient()
// This is a main component no new components shall be added here.
const App = () => {
  return (
    <div>

      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Body />} />
          <Route path="/team" element={<Team />} />
          <Route path="/login" element={<Login />} />
          <Route path="/guide" element={<Guide />} />
          <Route path="/languages" element={<Languages />} />
        </Routes>
      </Router>

    </div>
  )
}

export default App;
