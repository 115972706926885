import { width } from '@mui/system';
import React from 'react'
import Chart from 'react-apexcharts';
function StackedBarChart(props) {
    return (
        <React.Fragment>
            <div className="container-fluid"  >
                <Chart
                    type="bar"
                    width="700"
                    series={props.seriesData}
                    options={{
                        colors: ['#C4D9E8', '#E9D1CB', '#A1BD87', '#EBDF80', '#986B65', '#D18A99', '#C3C4C7', '#ED9F6E', '#789CAD', '#F5F5F5', '#7D7D7C', '#97D0EE', '#739CB0', '#D6EDF7', '#C4D9E8', '#789CAD', '#D1E8C4', '#575757', '#A1BD87'],
                        dataLabels: {
                            enabled: false,
                        },
                        chart: {

                            type: 'bar',
                            height: 350,
                            width: 100,
                            stacked: true,

                            toolbar: {
                                show: true

                            },
                            export: {
                                autoSelected: 'zoom'
                            },
                            xaxis: {
                                range: 5
                            },

                            zoom: {
                                enabled: true,
                                type: 'x',
                                autoScaleYaxis: false

                            }
                        },


                        responsive: [{
                            breakpoint: 480,
                            options: {
                                legend: {
                                    position: 'bottom',
                                    offsetX: -10,
                                    offsetY: 0
                                }
                            }
                        }],
                        plotOptions: {
                            bar: {
                                horizontal: false,
                                borderRadius: 10
                            },

                        },


                        xaxis: {
                            type: 'string',
                            range: 5,
                            categories: props.countryList,
                            width: '5px'

                        },
                        yaxis: {
                            show: false
                        },
                        legend: {
                            position: 'top',
                        },
                        fill: {
                            opacity: 1
                        },
                        dataLabels: {
                            enabled: false,
                            style: {
                                colors: ['#333']
                            },
                            offsetX: 30
                        },

                        plotOptions: {
                            series: {
                                stacking: 'normal'
                            }
                        },
                    }}
                />
            </div>

        </React.Fragment>
    )
}

export default StackedBarChart;