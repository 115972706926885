import { Box, Button, Paper, Stack, Divider } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react'

import CheckBoxControl from './FormControls/CheckBoxControl';

import BackButtonControl from './FormControls/BackButtonControl';
import ButtonControl from './FormControls/ButtonControl';
import { Typography } from '@mui/material';

import { GetAllCategories } from '../Handlers/Handlers.js';

function RequirementsSelectionPage(props) {

    const { requirements, onClickPrev, onClickNext, setCategories, updateMinMaxValues } = props;
    const categories = GetAllCategories();
    const [currentIndex, setCurrentIndex] = useState(1);

    const [currentCategory, setCurrentCategory] = useState();
    const [currentCategoryRequirements, setCurrentCategoryRequirments] = useState([])
    const [minMaxValues, setMinMaxValues] = useState(props.minMaxValues)

    const [selectedRequirements, setSelectedRequirements] = useState(requirements);

    const handleCheckBoxChange = (e) => {
        const { value, checked } = e.target;
        let tempArray = selectedRequirements;
        if (checked) {
            tempArray = [...tempArray, parseInt(value)]
        } else {
            tempArray = tempArray.filter((v) => { return v !== parseInt(value) })
        }
        setSelectedRequirements(tempArray);

    }

    const minMaxChange = (values) => {
        setMinMaxValues(values)
    }

    const onClickPrevHandler = () => {
        setCategories(categories);
        if (currentIndex !== 1) {
            setCurrentIndex(currentIndex - 1);
        }
        else {
            updateMinMaxValues(minMaxValues);
            onClickPrev(selectedRequirements);
        }
    }
    const onClickNextHandler = () => {
        setCategories(categories);
        if (currentIndex !== 7) {
            setCurrentIndex(currentIndex + 1)
        }
        else {
            updateMinMaxValues(minMaxValues);
            onClickNext(selectedRequirements);

        }
    }

    const getSelectedRequirementsList = () => {
        let requirementList = [];
        currentCategoryRequirements.map(requirement => {
            selectedRequirements.map(selectedRequirement => {
                if (requirement.id == selectedRequirement) {
                    requirementList.push(requirement.name);
                }
            });
        });
        return (<Fragment>
            {requirementList.map(requirement => { return <li key={requirement}>{requirement}</li> })}
        </Fragment>);


    }
    //getting requirements by id
    useEffect(() => {
        fetch("https://da-platform-backend-sfoip.ondigitalocean.app/api/v1/categories/" + currentIndex + "")
            .then((response) => response.json())
            .then((apiData) => {
                setCurrentCategory(apiData.data.id)
                setCurrentCategoryRequirments(apiData.data.requirements);
            });
    }, [currentIndex]);



    return (

        <Fragment>
            < Box sx={{
                display: 'flex',
                marginTop: 1,
                height: 300,
                border: 0,
            }}>
                <Stack direction="column" spacing={2} sx={{ width: '15%', alignItems: 'flex-start' }}>
                    {categories.map((category) => {
                        return (
                            <Button
                                key={category.id}
                                style={{
                                    margin: 1, backgroundColor: category.id == currentCategory ? '#ffffff' : 'transparent',
                                    color: category.id == currentCategory ? '#57819A' : '#90A684'
                                }}
                                sx={{
                                    width: '100%',
                                    boxShadow: 0,
                                    borderRadius: 1,
                                    color: 'white',
                                    fontSize: '2', // add text size
                                    whiteSpace: 'nowrap', // add no wrap
                                    textAlign: 'left',


                                }}
                            >
                                {category.name}
                            </Button>



                        );
                    })}
                </Stack>


                {/*<Paper sx={{
                    height: 300,
                    border: 0,
                    marginLeft: 0.1,
                    width: 230,
                    backgroundColor: 'white',
                    '&:hover': {
                        backgroundColor: 'white',
                        opacity: [0.9, 0.8, 0.7],
                    },
                    textAlign: 'left'



                }} elevation={3} > {

                        getSelectedRequirementsList()

                    }

                </Paper>*/}

                <Divider orientation="vertical" sx={{ height: '100%', mx: 1 }} />

                <Box width='100%'>
                    <Paper sx={{
                        // height: 300,
                        height: '300px',
                        overflowY: 'auto',
                        flexDirection: "column",
                        width: '90%',

                        marginLeft: 5,
                        border: 0,
                        borderRadius: '5px 5px 0 0',
                        backgroundColor: 'white',
                        /*'&:hover': {
                            backgroundColor: 'white',
                            opacity: [0.9, 0.8, 0.7],
                        },*/
                        textAlign: 'left',
                        paddingLeft: 5,
                        whiteSpace: 'nowrap',





                    }} elevation={3} >
                        {<CheckBoxControl
                            items={currentCategoryRequirements}
                            values={selectedRequirements}
                            isIndicator={false}
                            minMaxValues={minMaxValues}
                            minMaxChange={minMaxChange}
                            onChange={handleCheckBoxChange}


                        />}

                    </Paper>


                    <Paper style={{ backgroundColor: 'white', width: "93%", marginLeft: '4%', paddingRight: '1%', borderRadius: '0 0 5px 5px' }}>

                        <Stack justifyContent="flex-end" direction="row" spacing={2}>
                            <BackButtonControl onClick={onClickPrevHandler} label={"Back"} variant="outlined" />
                            <ButtonControl onClick={onClickNextHandler} label={"Next"} />
                        </Stack>

                    </Paper>
                </Box>

            </Box>






        </Fragment >
    )
}

export default RequirementsSelectionPage