import { useEffect, useState } from 'react';
import { Chip, FormControl, FormLabel, Grid, IconButton, Stack } from '@mui/material';

import Card from '../UIComponents/Card';
import InputControl from '../UIComponents/FormControls/InputControl';
import CheckBoxControl from '../UIComponents/FormControls/CheckBoxControl';
import DatePickerControl from '../UIComponents/FormControls/DatePickerControl';
import ButtonControl from '../UIComponents/FormControls/ButtonControl';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import './Framing.css';
const Framing = (props) => {
    // This framingInfo holdes Header and title for every Step
    const framingInfo = {
        header: "How can your decision be framed? ​",
        description: "Please answer the following questions and frame your decision precisely.",
        bodyClassName: "cardBody",
        pClassName: "pBody"
    }

    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [values, setValues] = useState(props.defaultValues);
    const [teammates, setTeammates] = useState(props.defaultValues.framingTeam)
    const [teammateName, setTeammateName] = useState();
    const [teammateEmail, setTeammateEmail] = useState();
    const [teammateRole, setTeammateRole] = useState();
    const [teammateMessage, setTeammateMessage] = useState();
    const checkboxItems = [
        {
            id: "1",
            name: "By exporting the final product from the current production location",
            description: ""
        },
        {
            id: "2",
            name: "By exporting product parts and assembling the product in the new region",
            description: ""
        },
        {
            id: "3",
            name: "By producing the product in the new region using its resources",
            description: ""
        }
    ]

    const handleInputChange = e => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });

    }
    const handleCheckBoxChange = e => {
        const { value, checked } = e.target;
        const tempObject = {};
        let tempArray = [...values.framingPlan]

        if (checked) {
            tempArray = [...tempArray, value]
        } else {
            tempArray = values.framingPlan.filter((v) => { return v !== value })
        }

        setValues({
            ...values,
            framingPlan: tempArray
        });
        setError(false);

    }

    const handleDateChange = (newDate) => {
        setValues({
            ...values,
            framingDate: newDate
        });

    }

    const onClickNextHandler = () => {
        setValues({
            ...values,
            framingTeam: teammates
        });

        for (const key in values) {
            if (key === "framingTitle") {
                if (values[key].trim() === "") {
                    values[key] = "decision 1"
                }
            }
        }
        props.setData(values);
        /*if (values.framingPlan.length === 0) {
            setError(true);
        }

        if (values.framingPlan.length) {
            props.onClickNext(1);
        }*/

        props.onClickNext(1);

    }

    const handleAddTeam = e => {
        setTeammateName();
        setTeammateEmail();
        setTeammateRole();
        setTeammateMessage();
        handleOpen();


    }

    const onClickAddTeamMate = () => {

        let isKeyExists = false;
        if (teammates.length > 0) {
            teammates.map(teammate => {
                //console.log(teammate)
                for (const key in teammate) {
                    if (key === teammateName) {
                        //console.log("yes");
                        isKeyExists = true;
                        teammate[teammateName].name = teammateName;
                        teammate[teammateName].email = teammateEmail;
                        teammate[teammateName].role = teammateRole;
                        teammate[teammateName].message = teammateMessage;

                    }

                }
            })
        }
        if (isKeyExists === false && typeof teammateName !== "undefined") {
            setTeammates([...teammates, { [teammateName]: { "name": teammateName, "email": teammateEmail, "role": teammateRole, "message": teammateMessage } }]);

        }

        handleClose();
    }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,

        // media query for smaller screen sizes
        '@media (max-width: 600px)': {
            width: '80%', // decrease the width to 80% of the container
        },
    };
    useEffect(() => {
        setValues({
            ...values,
            framingTeam: teammates
        });
    }, [teammates]);
    return (

        <div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid container spacing={2} alignItems="center" >
                        <Grid item xs={11}>
                            <Grid container spacing={2} alignItems="center" >

                                <Grid item xs={11}>
                                    <FormLabel>Who of your team or expert should get involved?​</FormLabel>
                                </Grid>
                                <Grid item xs={11}>
                                    <InputControl
                                        label="Name"
                                        name="teammateName"
                                        value={teammateName}
                                        onChange={(e) => setTeammateName(e.target.value)}

                                    />
                                </Grid>
                                <Grid item xs={11}>
                                    <InputControl
                                        label="Email"
                                        name="teammateEmail"
                                        value={teammateEmail}
                                        onChange={(e) => setTeammateEmail(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={11}>
                                    <InputControl
                                        label="Role"
                                        name="teammateRole"
                                        value={teammateRole}
                                        onChange={(e) => setTeammateRole(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={11}>
                                    <InputControl
                                        label="Message"
                                        name="teammateMessage"
                                        value={teammateMessage}
                                        onChange={(e) => setTeammateMessage(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={11}>
                            <Stack justifyContent="flex-end" direction="row" spacing={2}>
                                <ButtonControl label={"Ok"} onClick={onClickAddTeamMate} />
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>

            <Card cardInfo={framingInfo} ClassName="cardBody" >

                <form classes="framingForm"    >
                    <Grid container spacing={2} alignItems="center" >
                        <Grid item xs={11}>
                            <InputControl
                                label="What title would you give your decision? "
                                name="framingTitle"
                                value={values.framingTitle}
                                onChange={handleInputChange}
                            />

                        </Grid>
                        {/* <Grid item xs={6}>
                            <FormControl
                                required
                                error={error}
                            >
                                <FormLabel>How do you plan to scale your clean technology?</FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <CheckBoxControl
                                name="framingPlan"
                                items={checkboxItems}
                                values={values.framingPlan}
                                onChange={handleCheckBoxChange} />
                        </Grid>*/}
                        <Grid item xs={6}>
                            <FormLabel>Who (of your team, experts,etc.) has to get involved?</FormLabel>
                            <Tooltip title="You may not want to complete this decision tool alone and would like to invite experts to critically review your assessments. Simply and conveniently send an invitation link that allows the experts to go directly to your project and make changes." arrow>
                                <IconButton>
                                    <InfoOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={5}>
                            {
                                teammates.map((teammate) =>
                                    Object.keys(teammate).map((key) =>
                                        <Chip
                                            label={key}
                                            variant="outlined"
                                            onClick={(e) => {
                                                setTeammateName(teammate[key].name);
                                                setTeammateEmail(teammate[key].email);
                                                setTeammateRole(teammate[key].role);
                                                setTeammateMessage(teammate[key].message);
                                                handleOpen();
                                                //console.log(teammate);

                                            }}
                                            onDelete={(e) => {

                                                setTeammates(current => {
                                                    const updatedTeammates = current.filter(data => !data[key]);
                                                    //console.log(updatedTeammates);
                                                    return updatedTeammates;
                                                });
                                            }}
                                        />
                                    ))
                            }

                            <IconButton aria-label="delete" onClick={handleAddTeam} >
                                <PersonAddOutlinedIcon style={{ color: "#1c5A73" }} />
                            </IconButton>
                        </Grid>
                        <Grid item xs={6} >
                            <FormLabel>By what date your decision must be made?</FormLabel>
                            <Tooltip title="Set a deadline for your decision. Our tool will remind you by email before the deadline. ​" arrow>
                                <IconButton>
                                    <InfoOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={5} >
                            <DatePickerControl
                                label={""}
                                name="framingDate"
                                value={values.framingDate}
                                onChange={handleDateChange}
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <Stack justifyContent="flex-end" direction="row" spacing={2} style={{ marginTop: '16%' }} >
                                <ButtonControl label={"Next"} onClick={onClickNextHandler} />

                            </Stack>
                        </Grid>
                    </Grid>
                </form>

            </Card>
        </div >
    )
};

export default Framing;