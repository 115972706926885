import { Chip, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import Autocomplete from '../UIComponents/Autocomplete';
import WorldMap from "react-svg-worldmap";
import AutoCompleteCountry from "./AutoCompleteCounty";
let context_state;

function Map(props) {
    const countries = [
        { id: "IN", countryName: "India" },
        { id: "PK", countryName: "Pakistan" },
        { id: "DE", countryName: "Germany" },
        { id: "RU", countryName: "Russia" },
        { id: "CA", countryName: "Canada" },
        { id: "MX", countryName: "Mexico" },
        { id: "TR", countryName: "Turkey" },
        { id: "ES", countryName: "Spain" },
        { id: "CN", countryName: "China" },
        { id: "MA", countryName: "Morocco" },
        { id: "AL", countryName: "Albania" },
        { id: "MN", countryName: "Mangolia" },
        { id: "AO", countryName: "Angola" },
        { id: "NI", countryName: "Nicaragua" },
        { id: "HN", countryName: "Honduras" },
        { id: "GL", countryName: "Greenland" },
        { id: "IS", countryName: "Ice land" },
        { id: "NO", countryName: "Norway" },
        { id: "SE", countryName: "Sweden" },
        { id: "FI", countryName: "Finland" },
        { id: "LT", countryName: "Lithuania" },
        { id: "LV", countryName: "Latvia" },
        { id: "BW", countryName: "Botswana" },
        { id: "LA", countryName: "Lao PDR" },
        { id: "LK", countryName: "Srilanka" },
        { id: "AU", countryName: "Australia" },
        { id: "SA", countryName: "Saudi Arabia" },
        { id: "IQ", countryName: "Iraq" },
        { id: "SY", countryName: "Syria" },
        { id: "JO", countryName: "Jordan" },
        { id: "BY", countryName: "Belarus" },
        { id: "PL", countryName: "Poland" },
        { id: "BE", countryName: "Belgium" },
        { id: "GB", countryName: "United Kingdom" },
        { id: "IE", countryName: "Ireland" },
        { id: "FR", countryName: "France" },
        { id: "DZ", countryName: "Algeria" },
        { id: "MR", countryName: "Mauritania" },
        { id: "KG", countryName: "Kyrgyztan" },
        { id: "TJ", countryName: "Tajikistan" },
        { id: "UZ", countryName: "Uzbekistan" },
        { id: "EG", countryName: "Egypt" },
        { id: "LY", countryName: "Libya" },
        { id: "NE", countryName: "Niger" },
        { id: "ML", countryName: "Mali" },
        { id: "GN", countryName: "Guinea" },
        { id: "SN", countryName: "Senegal" },
        { id: "LR", countryName: "Liberia" },
        { id: "DO", countryName: "Dominican Republic" },
        { id: "CU", countryName: "Cuba" },
        { id: "US", countryName: "United States" },
        { id: "SV", countryName: "EI Salvador" },
        { id: "GT", countryName: "Guatemala" },
        { id: "VE", countryName: "Venezuela" },
        { id: "BR", countryName: "Brazil" },
        { id: "CO", countryName: "Colombia" },
        { id: "CR", countryName: "Costa Rica" },
        { id: "PA", countryName: "Panama" },
        { id: "PE", countryName: "Peru" },
        { id: "EC", countryName: "Ecuador" },
        { id: "BO", countryName: "Bolivia" },
        { id: "AR", countryName: "Argentina" },
        { id: "PY", countryName: "Paraguay" },
        { id: "FK", countryName: "Falkland Islands" },
        { id: "GY", countryName: "Guyana" },
        { id: "SR", countryName: "Suriname" },
        { id: "HT", countryName: "Haiti" },
        { id: "CI", countryName: "Cote d'Ivoire" },
        { id: "BF", countryName: "Burkina Faso" },
        { id: "GH", countryName: "Ghana" },
        { id: "NG", countryName: "Nigeria" },
        { id: "RO", countryName: "Romania" },
        { id: "UA", countryName: "Ukraine" },
        { id: "HU", countryName: "Hungary" },
        { id: "CZ", countryName: "Czech Republic" },
        { id: "DK", countryName: "Denmark" },
        { id: "CH", countryName: "Switzerland" },
        { id: "AT", countryName: "Austria" },
        { id: "SK", countryName: "Slovakia" },
        { id: "RS", countryName: "Serbia" },
        { id: "BA", countryName: "Bosnia and Herzegovina" },
        { id: "IT", countryName: "ITALY" },
        { id: "SI", countryName: "Slovenia" },
        { id: "CD", countryName: "Democratic Republic of TheCongo" },
        { id: "GA", countryName: "Gabon" },
        { id: "MM", countryName: "Mynmar" },
        { id: "TH", countryName: "Thailand" },
        { id: "KH", countryName: "Canbodia" },
        { id: "VN", countryName: "Viet Nam" },
        { id: "KR", countryName: "Korea South" },
        { id: "KP", countryName: "Korea North" },
        { id: "JP", countryName: "Japan" },
        { id: "ID", countryName: "Indonesia" },
        { id: "MY", countryName: "Malaysia" },
        { id: "PG", countryName: "Papua" },
        { id: "KE", countryName: "Kenya" },
        { id: "ET", countryName: "Ethiopia" },
        { id: "SD", countryName: "Sudan" },
        { id: "SS", countryName: "South Sudan" },
        { id: "YE", countryName: "Yemen" },
        { id: "OM", countryName: "Oman" },
        { id: "AF", countryName: "Afghanistan" },
        { id: "TM", countryName: "Turkmenistan" },
        { id: "BG", countryName: "Bulgaria" },
        { id: "GR", countryName: "Greece" },
        { id: "GE", countryName: "Georgia" },
        { id: "TK", countryName: "Tokelau" },
        { id: "KZ", countryName: "Kazakhstan" },
        { id: "IR", countryName: "Iran" },
        { id: "ZM", countryName: "Zambia" },
        { id: "ZW", countryName: "Zimbabwe" },
        { id: "ZA", countryName: "South Africa" },
        { id: "NA", countryName: "Namibia" },
        { id: "PH", countryName: "Philippines" },
        { id: "MG", countryName: "Madagascar" },
        { id: "SO", countryName: "Somalia" },
        { id: "NZ", countryName: "New Zealand" },
        { id: "UY", countryName: "Uruguay" },
        { id: "MZ", countryName: "Mozambique" },
        { id: "TD", countryName: "Chad" },
        { id: "CF", countryName: "Central African Republic" },
        { id: "CM", countryName: "Cameroon" },
        { id: "CG", countryName: "Republic of the Congo" },
        { id: "UG", countryName: "Uganda" },
        { id: "TZ", countryName: "Tanzania" },
        { id: "ZW", countryName: "Zimbabwe" },
        { id: "BW", countryName: "Botswana" },
        { id: "AZ", countryName: "Azerbaijan" },
        { id: "CL", countryName: "Chile" }];

    const [selectedCountries, setSelectedCountries] = useState([]);
    const [data, setData] = useState(props.options);
    const clickAction = (country) => {
        const tempObject = {
            id: country.countryCode,
            country: country.countryCode,
            countryName: country.countryName,
            value: country.minValue
        }

        if (isCountrySelected(country.countryCode)) {
            setData(current => current.filter(data => {
                return data.country !== country.countryCode;
            }),
            );
        }
        else {
            setData([...data, tempObject]);
        }

    }


    useEffect(() => {
        stylingFunction(context_state);
        props.setData(data);

    }, [data])

    const stylingFunction = (context) => {
        context.countryValue = context.countryName
        //console.log(context)
        context_state = context;
        const opacityLevel = 0.1 + (1.5 * (context.countryValue - context.minValue) / (context.maxValue - context.minValue))
        return {
            fill: isCountrySelected(context.countryCode) ? "#1c5a73" : context.color,
            fillOpacity: opacityLevel,
            stroke: "#1c5a73",
            valuePrefix: "ss",
            strokeWidth: 1,
            strokeOpacity: 0.2,
            cursor: "pointer",

        }
    }

    const isCountrySelected = (countryCode) => {
        return (data.some((d) => d.country == countryCode))
    }

    const handleAutoComplete = (values) => {
        //console.log(values)
        let tempArray = [];
        values.map(value => {
            const tempObject = {
                id: value.id,
                country: value.id,
                countryName: value.countryName,
                value: 0,

            }
            tempArray.push(tempObject);

        })
        //console.log(tempArray)
        setData(tempArray)
    }
    const getLocalizedText = (context) => {

        return context.countryName
    }


    //console.log(data)
    return (
        <div className="App">
            <Grid container spacing={1} justify="flex-start" style={{ height: "50px", width: "800px" }} >
                <div style={{ height: "500px", width: "600px" }} >

                    <WorldMap
                        size="lg"

                        data={data}
                        onClickFunction={clickAction}
                        styleFunction={stylingFunction}
                        tooltipTextFunction={getLocalizedText}

                    />
                </div>

                <Grid item xs={12} sm={6} md={4} lg={3} >

                    {
                        data.map((d) => <Chip label={d.country} variant="outlined" />)
                    }
                    <AutoCompleteCountry
                        title={"countries"}
                        defaultIndicators={selectedCountries}
                        countries={countries}
                        onChange={handleAutoComplete}

                    />
                </Grid>

            </Grid>

        </div>
    );
}

export default Map;