import React, { useState } from 'react'
import { FormControl, FormControlLabel, FormGroup, Grid } from '@mui/material';
import Switch from '@mui/material/Switch';
function MinMaxSwitch(props) {
    //console.log('props:', props);
    //console.log('minMaxValues:', props.minMaxValues);
    //console.log('id:', props.id);

    const [checked, setChecked] = useState(props.minMaxValues[props.id] === 1 ? true : false);
    props.updateMinMax(checked ? [props.id, 1] : [props.id, 0])
    const handleChange = (event) => {
        props.updateMinMax(event.target.checked ? [props.id, 1] : [props.id, 0])
        setChecked(event.target.checked);
    };
    return (


        <Switch
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
            sx={{
                /*"&.MuiSwitch-root .MuiSwitch-switchBase": {
                    color: "red"
                },*/


                "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
                    backgroundColor: '#57819A'
                },

                "& .MuiSwitch-switchBase.Mui-checked": {
                    color: "#57819A"
                }
            }}
        />
    )
}

export default MinMaxSwitch