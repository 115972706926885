import { FormGroup, FormLabel, Grid, Input, Stack } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react'
import Card from '../UIComponents/Card';

import ButtonControl from '../UIComponents/FormControls/ButtonControl';
import NumberPicker from '../UIComponents/FormControls/NumberPicker';
import BackButtonControl from '../UIComponents/FormControls/BackButtonControl';

function IndicatorWeighting(props) {

    const [indicators, setIndicators] = useState(props.indicators);
    //console.log(indicators)
    const [isShowPercentage, setShowPercentage] = useState(false)

    const indicatorsDBInfo = {
        header: "Which indicator is how meaningful?",
        description: `The following indicators weights have been assigned by Scaletech’s scaling experts. You can make adjustments by changing the numerical values for each indicator. `,
    };


    const NumberPickerHandler = (k, v, o) => {

        Object.entries(indicators[o]).map(([keyName, indicator]) => {
            if (indicator.id === k)
                indicator.weight = v;
        })
        setIndicators(indicators)


    }
    const percentageHandler = () => {
        Object.entries(indicators).map(([keyName, objectives]) => {
            let totalWeight = 0;
            Object.entries(objectives).map(([objKey, objValue]) => {

                const objectWeight = objValue.weight ?? 1
                totalWeight = totalWeight + objectWeight
                //console.log(keyName, totalWeight)
            })
            Object.entries(objectives).map(([objKey, objValue]) => {
                const objectWeight = objValue.weight ?? 1
                objValue.percentage = ((objectWeight / totalWeight) * 100).toFixed(2);
                //console.log(objValue.percentage)
            })
        })

        setShowPercentage(true);



    }

    const editValuesHandler = () => {
        setShowPercentage(false)
    }
    const onClickNextHandler = () => {
        props.setShowIndicatorWeighting1();
        props.onClickNext(1);
    }
    const onClickPrevHandler = () => {
        props.setShowIndicatorWeighting1();
        // props.onClickPrev(1);
    }
    return (
        <Card cardInfo={indicatorsDBInfo} >


            <Grid container spacing={4} >

                <Grid item xs={8}>
                    <Box sx={{ overflow: 'auto', maxHeight: '400px' }}>
                        <FormGroup>
                            {Object.entries(indicators).map(([keyName, objectives]) => {
                                return (
                                    <div key={keyName}>
                                        <b><FormLabel component="legend" style={{ fontWeight: 600 }}>{keyName}</FormLabel></b>
                                        {Object.entries(objectives).map(([objKey, objValue]) => {
                                            return (
                                                <div key={objKey}>
                                                    <Grid container columnSpacing={{ xs: 4, sm: 2, md: 4 }}>
                                                        <Grid item xs>
                                                            <Grid container spacing={10}>
                                                                <Grid item xs>
                                                                    <FormLabel style={{ marginTop: 10 }} component="legend">
                                                                        {objValue.title}
                                                                    </FormLabel>
                                                                </Grid>
                                                                <Grid item xs>
                                                                    {!isShowPercentage && (
                                                                        <NumberPicker
                                                                            weight={objValue.weight ?? 1}
                                                                            keyID={objValue.id}
                                                                            onChange={NumberPickerHandler}
                                                                            objectiveId={keyName}
                                                                        />
                                                                    )}
                                                                    {isShowPercentage && <Input disabled defaultValue={objValue.percentage} />}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </FormGroup>
                    </Box>
                </Grid>
                <Grid item xs={4} >
                    {!isShowPercentage && <ButtonControl label={"Calculate Percentage"} onClick={percentageHandler} />}
                    {isShowPercentage && <ButtonControl label={"Edit Values"} onClick={editValuesHandler} />}
                </Grid>
            </Grid>

            <Grid item xs={12} >
                <Stack justifyContent="flex-end" direction="row" spacing={2} style={{ marginTop: '0.13%' }}>
                    <BackButtonControl label={"Back"} onClick={onClickPrevHandler} variant="outlined" />
                    <ButtonControl label={"Next"} onClick={onClickNextHandler} />
                </Stack>
            </Grid>
        </Card >
    )
}

export default IndicatorWeighting