import { responsiveFontSizes } from '@mui/material';
import { useEffect, useState } from 'react';


const GetAllCategories = () => {
    const [categories, setCategories] = useState([]);
    useEffect(() => {
        fetch("https://da-platform-backend-sfoip.ondigitalocean.app/api/v1/categories")
            .then((response) => response.json())
            .then((apiData) => {
                setCategories(apiData.data);
            });
    }, []);

    return categories;

}


async function GetIndicatorData(indicator, createObject) {
    //console.log(indicator)

    const response = await fetch("https://da-platform-backend-sfoip.ondigitalocean.app/api/v1/indicators/" + indicator + "");
    let data = await response.json();
    //console.log(data)

    createObject(data.data);

}
/*async function GetCountryData() {


    const response = await fetch("https://da-platform-backend-sfoip.ondigitalocean.app/api/v1/indicators");
    let data = await response.json();

    //console.log(data.data);

}*/

async function getRequirementData(requirement, createObject) {
    let response = await fetch("https://da-platform-backend-sfoip.ondigitalocean.app/api/v1/requirements/" + requirement + "");
    let data = await response.json();
    createObject(data.data);
}



const GetAllRequirements = () => {
    const [requirements, setRequirements] = useState([]);
    useEffect(() => {
        fetch("https://da-platform-backend-sfoip.ondigitalocean.app/api/v1/categories")
            .then((response) => response.json())
            .then((apiData) => {
                setRequirements(apiData.data);
            });
    }, []);

    return requirements;

}

const GetRequirementsByCategory = (categoryID) => {

    const [requirements, setRequirements] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            await fetch("https://da-platform-backend-sfoip.ondigitalocean.app/api/v1/categories/" + categoryID + "")
                .then((response) => response.json())
                .then((apiData) => {

                    setRequirements(apiData.data.requirements);
                });
        }
        fetchData();
    }, []);
    //console.log(requirements)
    return requirements;
}

const GetIndicatorsByRequirement = (requirementID) => {

    const [indicators, setIndicators] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            await fetch("https://da-platform-backend-sfoip.ondigitalocean.app/api/v1/categories/" + requirementID + "")
                .then((response) => response.json())
                .then((apiData) => {
                    setIndicators(apiData.data.requirements);
                });
        }
        fetchData();
    }, []);

    return indicators;
}


const GetIndicators = (requirement) => {
    const [indicators, setIndicators] = useState([]);

    useEffect(() => {
        fetch("https://da-platform-backend-sfoip.ondigitalocean.app/api/v1/requirements/" + requirement + "")
            .then((response) => response.json())
            .then((apiData) => {
                apiData.data.indicators.map((indicator) => {
                    setIndicators(...indicators, indicator.id);

                });
            });
    }, []);
    return indicators

}




export { GetAllCategories, GetRequirementsByCategory, GetIndicators, GetIndicatorData, getRequirementData };