import React, { useState } from 'react';

import Card from '../UIComponents/Card';
import Map from '../UIComponents/Map.js';
import './Options.css';
import { Grid, Stack } from '@mui/material';
import ButtonControl from '../UIComponents/FormControls/ButtonControl';
import BackButtonControl from '../UIComponents/FormControls/BackButtonControl';

const Options = (props) => {
    const [options, setOptions] = useState(props.options);
    // This alternateInfo holds header and description for the alternatives step
    const alternateInfo = {
        header: "Are there regions that particularly interest you?​",
        description: "If you already have specific regions in mind that you want to compare, you can select them here.  \n                      Our analysis will then also compare the regions with other regions worldwide.​​"
    }

    const onClickNextHandler = () => {
        //console.log(options)
        props.setData(options)
        props.onClickNext(1);
    }
    const onClickPrevHandler = () => {
        props.setData(options)
        props.onClickPrev(1);
    }

    return (
        <div>
            <Card cardInfo={alternateInfo}  >
                <Map setData={setOptions} options={options} sx={{ borderRadius: '16px' }} />
                <Grid item xs={12} >
                    <Stack justifyContent="flex-end" direction="row" spacing={2} style={{ marginTop: '28.43%' }}>
                        <BackButtonControl label={"Back"} onClick={onClickPrevHandler} variant="outlined" />
                        <ButtonControl label={"Next"} onClick={onClickNextHandler} />
                    </Stack>
                </Grid>
            </Card>
        </div>


    )
};
export default Options;