import { TextField } from '@mui/material';

const InputControl = (props) => {

    const { name, label, value, onChange } = props;
    const style = {
        "& label.Mui-focused": {
            color: "#1C5A73"
        },
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: "#1C5A73"
            }
        }
    }
    return (
        <TextField
            fullWidth
            variant="outlined"
            label={label}
            name={name}
            value={value}
            labelplacement="center"
            onChange={onChange}
            sx={style}


        />
    )
}

export default InputControl;