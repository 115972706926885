import React, { Fragment, useState } from 'react';
import { Box, Button, Paper } from '@mui/material';
import { Stack } from '@mui/material';

import { GetAllCategories } from '../Handlers/Handlers.js';
import BackButtonControl from './FormControls/BackButtonControl.js';
import ButtonControl from './FormControls/ButtonControl.js';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import RequirementsSelectionPage from './RequirementsSelectionPage.js';
import EditRequirements from './EditRequirements.js';

const RequirementsPage = (props) => {
  const { requirements, onClickPrev, onClickNext, isRequirement, onClickRequirementHandler, setCategories, updateMinMaxValues } = props;
  const categories = GetAllCategories();

  const [paperHeights, setPaperHeights] = useState(Array(categories.length).fill(0));
  const [displayEditRequirements, setDisplayEditRequirements] = useState(false);
  const [minMaxValues, setMinMaxValues] = useState(props.minMaxValues)
  const [selectedRequirements, setSelectedRequirements] = useState(requirements);


  const getSelectedRequirementsList = (allRequirements) => {
    let requirementList = {};
    allRequirements.map(requirement => {
      requirements.map(selectedRequirement => {
        if (requirement.id == selectedRequirement) {
          requirementList[requirement.id] = requirement.name;
        }
      });
    });

    return (
      <Fragment>
        {Object.keys(requirementList).map((key, index) => {
          return isRequirement ? (
            <li key={key} style={{ display: "flex", alignItems: "center", marginRight: "10px" }}>
              <span style={{ marginRight: "2px" }}>&bull;</span>
              {requirementList[key]}
            </li>

          ) : (
            <Button
              key={key}
              variant="text"
              style={{
                color: 'blue',
                textDecoration: 'underline',
                justifyContent: 'flex-start',
                textTransform: 'none',
              }}
              onClick={onClickRequirement}
              id={key}
            >
              {requirementList[key]}
            </Button>
          );
        })}
      </Fragment>
    );
  };


  const onClickRequirement = (e) => {
    onClickRequirementHandler(e.target.id);
  };

  const onClickPrevHandler = () => {
    isRequirement ? onClickPrev(requirements) : onClickPrev(props.indicators);
  };

  const onClickNextHandler = () => {
    isRequirement ? onClickNext(requirements) : onClickNext(props.indicators);
  };

  const onPaperHeightChange = (index, height) => {
    setPaperHeights((prevHeights) => {
      const newHeights = [...prevHeights];
      newHeights[index] = height;
      return newHeights;
    });
  };
  const handleClick = () => {
    //console.log('Edit button clicked');
    //setDisplayEditRequirements(!displayEditRequirements);
    // Perform some action here
  };

  const onClickSaveHandler = () => {

    updateMinMaxValues(minMaxValues);
    onClickNext(selectedRequirements);


  }


  return (
    <Fragment>
      <Box sx={{ display: 'flex' }}>
        <Stack sx={{ width: '20%', height: '100%', overflow: 'auto', alignItems: 'stretch' }} spacing={2}>
          {categories.map((category, index) => (
            <Button
              key={category.id}
              style={{
                margin: 1,
              }}
              sx={{

                boxShadow: 0,
                borderRadius: 1,
                color: '#57819A',
                fontSize: '2', // add text size
                whiteSpace: 'nowrap', // add no wrap
                textAlign: 'left',
                backgroundColor: 'white',
                height: paperHeights[index] >= 40 ? paperHeights[index] : 40,


              }}

            >
              {category.name}
            </Button>

          ))}
        </Stack>

        < Stack sx={{ width: '78%', height: '100%' }} spacing={2}>

          {
            categories.map((category, index) => (
              <Paper
                style={{
                  margin: 1
                }}
                key={category.id}
                sx={{
                  display: 'flex',
                  alignItems: 'center', minHeight: 40, border: 0, width: '100%', backgroundColor: 'white',
                }}
                elevation={3}
                onHeightChange={(height) => onPaperHeightChange(index, height)}
              >
                {getSelectedRequirementsList(category.requirements)}
                <div>
                  <IconButton onClick={handleClick}>
                    {/*<EditIcon color="action" fontSize="small" style={{ marginLeft: 'auto' }} />*/}
                  </IconButton>
                  {/* display component only if displayComponent is true */}
                </div>

              </Paper>
            ))
          }
        </Stack>


        {displayEditRequirements && < EditRequirements
          requirements={requirements}
          setCategories={setCategories}
          minMaxValues={props.minMaxValues}
          updateMinMaxValues={updateMinMaxValues}

        />}
      </Box>

      <Paper style={{ backgroundColor: 'white', width: "97%", paddingRight: '1%', borderRadius: '0 0 5px 5px' }}>
        {/* {!displayEditRequirements &&*/}

        <Stack justifyContent="flex-end" direction="row" spacing={2}>
          <BackButtonControl onClick={onClickPrevHandler} label={"Back"} variant="outlined" />
          <ButtonControl onClick={onClickNextHandler} label={"Next"} />
        </Stack>
        {/* }
        {displayEditRequirements &&
          <Stack justifyContent="flex-end" direction="row" spacing={2}>
            <ButtonControl onClick={onClickSaveHandler} label={"save"} />

          </Stack>
        }*/}

      </Paper>

    </Fragment >
  )
}

export default RequirementsPage;
