import React, { Fragment, useEffect, useState } from 'react'
import Card from '../UIComponents/Card';
import { Checkbox, FormControlLabel, FormGroup, FormLabel, getFormLabelUtilityClasses, Grid, Input, Modal, Paper, Slider, Stack, TextField, Typography } from '@mui/material';
import NumberPicker from '../UIComponents/FormControls/NumberPicker';
import ButtonControl from '../UIComponents/FormControls/ButtonControl';
import BackButtonControl from '../UIComponents/FormControls/BackButtonControl';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import Chart from 'react-apexcharts';
import { Box } from '@mui/system';
import InputControl from '../UIComponents/FormControls/InputControl';


function Weighting(props) {

    const marks = [
        {
            value: 1,
            label: '1',
        },
        {
            value: 100,
            label: '100',
        },
    ];


    const [requirementWeigthing, setRequirementWeighting] = useState(props.requirementWeighting);
    //console.log(requirementWeigthing)
    useEffect(() => {
        getChartData();
    }, [])
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const getChartData = () => {
        let seriesData = [];
        let options = {
            labels: [], tooltip: { enabled: false }, colors: ['#C4D9E8', '#E9D1CB', '#A1BD87', '#EBDF80', '#986B65', '#D18A99', '#C3C4C7', '#ED9F6E', '#789CAD', '#F5F5F5', '#7D7D7C', '#97D0EE', '#739CB0', '#D6EDF7', '#C4D9E8', '#789CAD', '#D1E8C4', '#575757', '#A1BD87'],

        };

        requirementWeigthing.map(requirement => {
            options.labels.push(requirement.title)
            if (requirement.weight) {
                seriesData.push(requirement.weight)
            }
            else {
                seriesData.push(1)
            }
        });

        return [options, seriesData]

    }
    let [options, series] = getChartData();
    const weightingInfo = {
        header: "Which requirement is how important to you?",
        description: `Indicate the relative importance of the requirements by choosing a numerical value between 1 and 100 for each requirement.`,
    };


    const onClickNextHandler = () => {
        let error = true;
        props.setData(requirementWeigthing);
        const [options, series] = getChartData();
        series.map(number => {
            if (number > 1) {
                error = false;
            }
        })
        if (!error) {
            props.onClickNext(1);
        } else {
            setOpen(true);
        }

    }

    const onClickNextModel = () => {
        props.onClickNext(1);

    }
    const onClickPrevHandler = () => {
        props.setData(requirementWeigthing);
        props.onClickPrev(1);
    }


    const handleSliderChange = Name => (event, newValue) => {

        const tempObject = requirementWeigthing.map(object => {
            if (object.id === Name) {
                return { ...object, weight: newValue };
            }
            return object;
        });
        setRequirementWeighting(tempObject);
        percentageHandler();
        [options, series] = getChartData();


    }

    const percentageHandler = () => {
        let totalWeight = 0;
        Object.entries(requirementWeigthing).map(([keyName, objective]) => {
            const objectWeight = objective.weight ?? 1
            totalWeight = totalWeight + objectWeight

        })
        Object.entries(requirementWeigthing).map(([keyName, objective]) => {
            const objectWeight = objective.weight ?? 1
            objective.percentage = ((objectWeight / totalWeight) * 100).toFixed(1);
        })

    }
    const onclickShowIndicatorsWeighting = () => {
        props.setData(requirementWeigthing);
        props.setShowIndicatorWeighting1();
        // props.onClickNext(1);
    }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    return (
        <Fragment>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>

                    <Grid container spacing={2} alignItems="center" >
                        <Grid item xs={11}>
                            <Grid container spacing={2} alignItems="center" >
                                <Grid item xs={11}>
                                    <FormLabel>If you continue without adapting the importance of your requirements, all requirements will be weighted equally.  ​​</FormLabel>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack justifyContent="flex-end" direction="row" spacing={1}>
                                <ButtonControl label={"Edit"} onClick={handleClose} />
                                <ButtonControl label={"Next"} onClick={onClickNextModel} />
                            </Stack>
                        </Grid>
                    </Grid>

                </Box>
            </Modal>
            <Card cardInfo={weightingInfo} >

                <Grid container spacing={4} >
                    <Grid container spacing={3} justify="flex-start" >
                        <Grid item xs={12} >
                            <Stack justifyContent="flex-end" direction="row">
                                <BackButtonControl label={"Indicator Weighting"} onClick={onclickShowIndicatorsWeighting} color="action" />
                                <Tooltip title="give weight to each indicator" arrow>
                                    <IconButton>
                                        <InfoOutlinedIcon />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </Grid>
                        <br />
                    </Grid>
                    <Grid item xs={6}>
                        <Box style={{ maxHeight: '360px', overflowY: 'auto', overflowX: 'hidden' }}>
                            <FormGroup sx={{ flexDirection: 'column', flexWrap: 'nowrap' }}>
                                {Object.entries(requirementWeigthing).map(([mainKey, mainValue]) => (
                                    <Fragment>
                                        <Grid container rowSpacing={10} columnSpacing={{ xs: 12, sm: 8, md: 12 }}>
                                            <Grid item xs={6} center={0 ? 0 : 1} >
                                                <FormLabel component="legend" >{mainValue.title}</FormLabel>
                                            </Grid>
                                            <Grid item xs={6} center={0 ? 0 : 1} >
                                                <Box sx={{ margin: '0 20px' }}>
                                                    <Slider
                                                        aria-label="Default"
                                                        defaultValue={mainValue.weight}
                                                        valueLabelDisplay="Auto"
                                                        step={5}
                                                        min={0}
                                                        max={100}
                                                        marks={marks}
                                                        onChange={handleSliderChange(mainValue.id)}
                                                        sx={{
                                                            '& .MuiSlider-thumb': {
                                                                color: "#57819a"
                                                            },
                                                            '& .MuiSlider-track': {
                                                                color: "#57819a"
                                                            },
                                                            '& .MuiSlider-rail': {
                                                                color: "#57819a"
                                                            },
                                                            '& .MuiSlider-active': {
                                                                color: "#57819a"
                                                            }
                                                        }}
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Fragment>
                                ))}
                            </FormGroup>
                        </Box>
                    </Grid>
                    <Grid item xs={6} center={0 ? 0 : 1} >

                        <Chart options={options}
                            series={series} type="pie" width="470" />
                    </Grid>

                </Grid>

                <Grid item xs={12} >
                    <Stack justifyContent="flex-end" direction="row" spacing={2} style={{ marginTop: '0.13%' }}>
                        <BackButtonControl label={"Back"} onClick={onClickPrevHandler} variant="outlined" />
                        <ButtonControl label={"Next"} onClick={onClickNextHandler} />
                    </Stack>
                </Grid>
            </Card >
        </Fragment>
    )
}

export default Weighting;